// import React from "react";
// import { GatsbyImage } from "gatsby-plugin-image";

// const Image = ({ image, cssClass }) => {
//   return (
//     <div
//       className={
//         cssClass
//           ? ["module module__image", cssClass].join(" ")
//           : "module module__image"
//       }
//     >
//       <div className="module__box">
//         {image ? (
//           <img
//             className="module__image-img"
//             src={image.sourceUrl}
//             alt={image.altText}
//             title={image.title}
//             srcSet={image.srcSet}
//             // sizes={image.sizes}
//             loading={"lazy"}
//             width={image.mediaDetails.width}
//             height={image.mediaDetails.height}
//           />
//         ) : null}
//       </div>
//     </div>
//   );
// };

// export default Image;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = ({ image, cssClass }) => {
  return (
    <div
      className={
        cssClass
          ? ["module module__image", cssClass].join(" ")
          : "module module__image"
      }
    >
      <div className="module__box">
        {image ? (
          <GatsbyImage
            className="module__image-img"
            image={image.imageFile.childImageSharp.gatsbyImageData}
            alt={image.altText}
            title={image.title}
            loading={"lazy"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Image;
